import './Sidebar.css'
import {  IoSettingsOutline, IoLogInOutline, IoLogOutOutline } from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'


const Sidebar = () => {

  const url = process.env.REACT_APP_API_URL;

  const [user, setUser] = useState(null);
    const [botName, setBotName] = useState(null);
    const [botLastName, setBotLastName] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [bgcolor, setBgcolor] = useState(null);
    const navigate = useNavigate();
    const handleSignOut = (e) => {
      e.preventDefault();
  
      auth
        .signOut()
        .then(() => {
          window.location.reload();
          navigate("/");
          setUser(null);
        })
        .catch((error) => {
          console.log("Sign out error:", error);
        });
    };
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        }
      });
  
      return () => unsubscribe();
    }, []);

    

    useEffect(() => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setBotName(data.bot_first_name);
          setBotLastName(data.bot_last_name)
          setAvatar(data.bot_avatar)
          setBgcolor(data.sidebar_bg_color)
        })
        .catch((error) => {
          console.error("Error fetching bot_name:", error);
        });
    }, []);
  
    
    return ( 
        <div className='ws' style={{backgroundColor:bgcolor}}>
     
       
       <div className='top'>
       <div className='strip'>
       <h1></h1>
        <p className='head'>{botName}<span className='dot'>.</span>{botLastName}</p> 
        </div>
        <div className='options'>
        <a href='https://www.kreatebots.com/'><p className='icon'><AiOutlineHome color="white"/></p></a>
        <Link to='/'><p className='icon'><FaRobot color='white'/></p></Link> 
        <Link to='/Home'><p className='icon' id='setting'> <IoSettingsOutline color='white'  className='icons'/></p></Link>
        <div className='signin'>
        {user ? (
                  <div onClick={handleSignOut}><span className='icon'><IoLogOutOutline color='white'/></span><span className='signup'>&nbsp;Sign out</span></div>
                  ) : (
                    <Link to='/login'><span className='icon'><IoLogInOutline color='white'/></span><span className='signup'>&nbsp;Sign in</span></Link>
                  )}
        </div>

        </div>
        

        </div>

        <img src={avatar} className="hero"></img>

        </div>

     );
}
 
export default Sidebar;