import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../BotAvatar/UserAvatar';
import StartBtn from '../Components/StartBtn';
import Review from '../Components/Review';
import Select from '../Components/Select';
import './Chatbot.css'

const config = {
    initialMessages: [createChatBotMessage(`Hey buddy! Welcome to Kreate-Bots!🤗`, {
      widget: 'startBtn',
    })],
    botName: "Kreate.Bots",
    customComponents:{
      botAvatar: (props) => <BotAvatar/>,
      userAvatar: (props) => <UserAvatar/>
    },
    widgets:[
      {
        widgetName: 'startBtn',
        widgetFunc: (props) => <StartBtn {...props} />,
      },
      {
        widgetName: 'select',
        widgetFunc: (props) => <Select {...props} />,
      },
      {
        widgetName: 'review',
        widgetFunc: (props) => <Review {...props} />,
      },
    ]
  
};

export default config;