import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import MessageParser from './chatbot/bot/ChatBot/MessageParser.js';
import ActionProvider from './chatbot/bot/ChatBot/ActionProvider.js';
import config from './chatbot/bot/ChatBot/config.js';
import './App.css'
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom' 
import Sidebar from './chatbot/sidebar/Sidebar.js';
import Login from './chatbot/pages/auth/login/index.js'
import ResetPasswordPage from './chatbot/pages/auth/forgotPassword/index.js'
import Signup from './chatbot/pages/auth/signUp/index.js'
import Home from './chatbot/knob-component/Home.js';

const Main = () => {

    return (
         <div className='App'>
          <Router>

          <Sidebar/>
          
          <Routes>
          <Route exact path='/' element={<Chatbot
            className="cb"
            id='cbt'
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          /> }/>

        <Route path="forgot_password" element={<ResetPasswordPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/Home' element={<Home/>}/>
          </Routes>


          </Router>
    </div>
     );
}
 
export default Main;