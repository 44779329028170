import { createClientMessage } from 'react-chatbot-kit';
import React from 'react';
import { useState, useEffect } from 'react';
import { auth } from '../../../firebase';
import { Toggle } from '../Components/Toggle';
import axios from 'axios';
import './Chatbot.css'
import { BiSolidPurchaseTag } from "react-icons/bi";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [copyText,setCopyText]=useState('')
  const handleCopy=()=>{
      navigator.clipboard.writeText(copyText)
  }
  const [limit,setLimit] = useState(40)
  const [file, setFile] = useState();
  function handleFile(event){
      setFile(event.target.files[0]);
      console.log(event.target.files[0]);
  }
  const [credit, setCredit] = useState(100);

  async function handleUpload() {
  secondAction(file)
  }

  const [inputText, setInputText] = useState("");

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = () => {
   secondWebAction(inputText)
  };


  const [username, setUsername] = useState("free_user");

  const [botName, setBotName] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [api1, setApi1] = useState("");

  
  const logState = state => {
    console.log("Toggled:", state)
}


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setCredit(1000);
        console.log(credit + 'credit')
        setUsername(currentUser.displayName.split(' ').join('').toLowerCase())
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL)
      .then((response) => response.json())
      .then((data) => {
        setBotName(data.bot_first_name + "-" + data.bot_last_name)
        setQ1(data.questions.q1);
        setQ2(data.questions.q2);
        setApi1(data.api_1);

      })
      .catch((error) => {
        console.error("Error fetching bot_name:", error);
      });
  }, []);
  
  const pdfuploadfunction = () => {
    const pd = document.getElementById('pdfid');
    pd.style.display="block";
    const wd = document.getElementById('webid');
    wd.style.display="none";
  }

  const webuploadfunction = () => {
    const wd = document.getElementById('webid');
    wd.style.display="block";
    const pd = document.getElementById('pdfid');
    pd.style.display="none";

  }
  
  const initialAction = async () => {
    const cmessage = createClientMessage("Let's get started ");
    updateState(cmessage, "");
    await selectAction();
  };

  const selectAction = async () =>{
    const message = createChatBotMessage("Which file do you want to upload?",{
      widget:'select'
    });
    updateState(message);
  }

  
  const firstAction = async () =>{
    pdfuploadfunction();
    const cmessage = createClientMessage("PDF");
    updateState(cmessage) ;
    const message = createChatBotMessage(q1, {
      widget:'empty'
    });
    updateState(message);
  }

  const webAction = async () =>{
    webuploadfunction();
    const cmessage = createClientMessage("Web Page");
    updateState(cmessage) ;
    const message = createChatBotMessage("You can enter your web page in the sidebar", {
      widget:'test',
    });
    updateState(message);
  }

  
  const secondAction = (file) =>{
    if(credit>0){
    const cmessage = createClientMessage("done!");
    updateState(cmessage) ;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("name", username);
    axios.post(api1, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  .then((response) => {
    setCredit(credit-20)
    setIsLoading(false);
    const message = createChatBotMessage(q2, {
      widget:'test',
    });
    updateState(message, "query") ;
  })
  .catch((error) => {
    console.error('Error uploading PDF:', error);
  });
}
else{
  const message = createChatBotMessage('oops! you are out of credits😔', {
    widget:'test',
  });
  updateState(message, "query") ;
}
  }

  const secondWebAction = (wp) =>{
    if(credit>0){
    const cmessage = createClientMessage("done!");
    updateState(cmessage) ;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("web", wp);
    formData.append("name", username);
    axios.post("https://weburl-bot-5wz7dep6ya-uc.a.run.app/web", formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  .then((response) => {
    setCredit(credit-20)
    setIsLoading(false);
    const message = createChatBotMessage("Webpage has been processed. You can ask me any queries", {
      widget:'test',
    });
    updateState(message, "queryweb") ;
  })
  .catch((error) => {
    console.error('Error uploading PDF:', error);
  });
}
else{
  const message = createChatBotMessage('oops! you are out of credits😔', {
    widget:'test',
  });
  updateState(message, "query") ;
}

  }

  const queryAction = (formData) =>{
    if(credit>0){
    formData.append("limit", limit);
    axios.post('https://pdf-bot-5wz7dep6ya-uc.a.run.app/ask', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      setCredit(credit-10)
      setCopyText(response.data.answer)
      const message = createChatBotMessage(response.data.answer, {
        widget:'review',
      });
      updateState(message, "query") ;
    })
    .catch((error) => {
      console.error('Error uploading PDF:', error);
    });
  }
  else{
    const message = createChatBotMessage('oops! you are out of credits😔', {
      widget:'test',
    });
    updateState(message, "query") ;
  }
  }

  const queryWebAction = (formData) =>{
    if(credit>0){
    formData.append("limit", limit);
    axios.post('https://weburl-bot-5wz7dep6ya-uc.a.run.app/ask', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      setCredit(credit-10)
      setCopyText(response.data.answer)
      const message = createChatBotMessage(response.data.answer, {
        widget:'review',
      });
      updateState(message, "queryweb") ;
    })
    .catch((error) => {
      console.error('Error uploading PDF:', error);
    });
  }
  else{
    const message = createChatBotMessage('oops! you are out of credits😔', {
      widget:'test',
    });
    updateState(message, "query") ;
  }
  }

  const updateState = (message, checker) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
    })) 
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            initialAction,
            selectAction,
            webAction,
            handleCopy,
            firstAction,
            secondAction,
            secondWebAction,
            queryAction,
            queryWebAction,
          },
        });
      })}



<div className="range">
  <div>
    <span>( Available Credit : {credit} )</span> <span className='credit'>&nbsp;<BiSolidPurchaseTag style={{color:"white"}}/>Buy More</span>
  
  </div>
  <br/>
    <Toggle
            label="anonymize"
            toggled={false}
            onClick={logState}
            id="anonymityBtn"
        />
        <br/>
    <p className='ans'>Answer word limit</p>
    <input  type="range" min="0" max="100" step="10" value={limit} onChange={(e)=>setLimit(e.target.value)} style={{color:"yellow"}}/>
    <p>{limit}</p>
    <div className='pdfu' id='pdfid'>
    <p className='updf'>Upload pdf</p>
        <input type="file" name="file" onChange={handleFile} className='pdf' ></input>
        <br/>
        <button  onClick={handleUpload} className='upload'>upload</button>
    </div>

    <div className='webu' id='webid'>
      <p className='uweb'>Upload  Web Page</p>
      <input
        type="text"
        value={inputText}
        onChange={handleChange}
        placeholder="Web Page Url"
        className='web'
      />
    <br/>
    <button type="submit" className='uploadw' onClick={handleSubmit}>Submit</button>
    </div>
    </div>


          
{isLoading && (
  <div class="shapes"></div>
)}


    </div>
  );
};

export default ActionProvider;

