import React, { useState } from 'react';
import './Chatbot.css'


const MessageParser = ({ children, actions, lim}) => {

  const { checker } = children.props.state;
  

  const parse = (message) => {
  
    if(checker==="query"){
      const formData = new FormData();
      formData.append("question", message);
      actions.queryAction(formData);
  };

  if(checker==="queryweb"){
    const formData = new FormData();
    formData.append("question", message);
    actions.queryWebAction(formData);
  };
}

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}


    
    </div>
  );
};

export default MessageParser;