import React from 'react'

export default function Select(props) {

    const pdfAction = () => {
        props.actions.firstAction();
    }
    const webPageAction = () => {
        props.actions.webAction();
    }

    return (
        <div>
            <button className='start' onClick={() => pdfAction()}>PDF</button>
            <button className='start1' onClick={() => webPageAction()}>Web Page</button>
        </div >
    )
}
